import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

const RootContext = createContext("root")

const RootProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false)

  return (
    <RootContext.Provider
      value={{
        updateLoading: bool => setLoading(bool),
        loading: isLoading,
      }}
    >
      {children}
    </RootContext.Provider>
  )
}

RootProvider.propTypes = {
  children: PropTypes.object,
}

export { RootContext, RootProvider }
