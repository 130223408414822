import React, { useContext, Suspense } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "./header.module.css"

import SiteIcon from "../../svg/app-icon-6.svg"
import useDarkMode from "use-dark-mode"
import { RootContext } from "../root-provider"

const DarkModeToggle = React.lazy(() => import("./dark-mode-toggle"))

const Header = ({ presidentsCup, ryderCup }) => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  const darkMode = useDarkMode()

  const rootContext = useContext(RootContext)

  const isSSR = typeof window === "undefined"

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div
          className={styles.pageToggle}
          style={{ textAlign: "left", fontSize: "85%" }}
        >
          <div>
            {!isSSR && (
              <Suspense fallback={<span></span>}>
                <DarkModeToggle
                  style={{ marginTop: "8px" }}
                  checked={!darkMode.value}
                  onChange={darkMode.toggle}
                  size={20}
                  sunColor="#fff"
                />
              </Suspense>
            )}
          </div>
          {presidentsCup && (
            <nav>
              <Link
                className={styles.pageNav}
                activeClassName={styles.pageNavActive}
                to="/presidentscup/"
              >
                Presidents Cup
              </Link>
            </nav>
          )}
        </div>
        <SiteIcon className={styles.logo} onClick={scrollTop} />
        <div className={styles.pageToggle}>
          <nav>
            {ryderCup && (
              <Link
                className={styles.pageNav}
                activeClassName={styles.pageNavActive}
                to="/rydercup/"
              >
                Ryder
              </Link>
            )}
            <Link
              className={styles.pageNav}
              activeClassName={styles.pageNavActive}
              to="/"
            >
              PGA
            </Link>
            <Link
              className={styles.pageNav}
              activeClassName={styles.pageNavActive}
              to="/europeantour/"
            >
              Euro
            </Link>
          </nav>
        </div>
      </div>
      <div
        className={
          rootContext.loading
            ? styles.lineLoadingIndicatorActive
            : styles.lineLoadingIndicator
        }
      ></div>
    </header>
  )
}

Header.propTypes = {
  presidentsCup: PropTypes.bool,
  ryderCup: PropTypes.bool,
}

Header.defaultProps = {
  presidentsCup: false,
  ryderCup: false,
}

export default Header
