import React from "react"

import Header from "../components/layout/header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <div className="page-wrap">
    <SEO title="404: Not found" />
    <Header />
    <Layout>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  </div>
)

export default NotFoundPage
